<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
              fluid
              :src="$root.appLogo"
              alt="Login V2"
      />

      <!--<h2 class="brand-text text-primary ml-1">-->
        <!--{{$root.appName}}-->
      <!--</h2>-->
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{$t('label_page_not_found')}} 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          {{$t('label_page_not_found_desc')}}
        </p>

   <div>
     <b-button
             variant="primary"
             class="mb-2 btn-sm-block"
             @click="$router.go(-1)"
     >
       {{$t('label_back')}}
     </b-button>
   </div>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>

import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
